<script setup>
import { onMounted, ref } from 'vue';
import BaseControl from './BaseControl.vue';

const props = defineProps({
    id: String,
    help: String,
    label: String,
    error: String,
    modelValue: String|Number,
    prefix: String,
    suffix: String,
    type: {
        type: String,
        default: 'text'
    }
});

defineEmits(['update:modelValue', 'input']);

const input = ref(null);

onMounted(() => {
    if (input.value.hasAttribute('autofocus')) {
        input.value.focus();
    }
});

defineExpose({ focus: () => input.value.focus() });
</script>

<template>
    <BaseControl v-bind="props">
        <div class="mt-1 flex rounded-md shadow-sm">
            <span v-if="prefix" class="inline-flex items-center rounded-l-md border border-r-0 border-gray-300 bg-gray-50 px-3 text-gray-500 sm:text-sm">{{ prefix }}</span>
            <input
                :id="id"
                :type="type"
                :value="modelValue"
                class="mt-1 block w-full border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 rounded-md shadow-sm"
                @input="$emit('update:modelValue', $event.target.value), $emit('input', $event)"
                v-bind="{ ...$props, ...$attrs }"
                ref="input"
            />  
            <span v-if="suffix" class="inline-flex items-center rounded-r-md border border-l-0 border-gray-300 bg-gray-50 px-3 text-gray-500 sm:text-sm">{{ suffix }}</span>
        </div>
        <p v-if="help" class="mt-2 text-xs text-gray-400">{{ help }}</p>
    </BaseControl>
</template>